import { Rows } from "../types/types";
import { buildApiUrl } from "../utils/build-api-url";

export const fetchTeamsSpreadsheet = async () => {
  const url = buildApiUrl(
    "1NNjSAF2mwL3_l8C35eHZaCH2DZM-9SmXQbnLGx4PzDI",
    "Lag"
  );

  const response = await fetch(url);

  if (!response.ok) {
    throw new Error(
      `Failed to fetch teams data, status code: ${response.status}`
    );
  }

  const data = await response.json();

  return data.values as Rows;
};
