import { Typography, Chip, Stack, List, ListItem } from "@mui/material";
import { useKiosk } from "./providers/KioskProvider";
import { Fragment } from "react/jsx-runtime";

type Size = "small" | "large";

const Kiosk = ({
  size,
  maxColumns = 2,
}: {
  size?: Size;
  maxColumns?: number;
}) => {
  const { categories } = useKiosk();

  const listItemsStyle = {
    border: 0,
    p: 0,
    color: "white",
    "&:last-child td, &:last-child th": { border: 0 },
    "&:nth-of-type(odd)": {
      backgroundColor: "#191919",
      // outline: "1px solid #0d0d0d",

      "td:last-child": {
        backgroundColor: "",
      },
    },
    "&:nth-of-type(even)": {
      backgroundColor: "#111111",
      // outline: "1px solid #000000",

      "td:last-child": {
        backgroundColor: "",
      },
    },
  };

  const isSmallSize = size === "small";

  return (
    <>
      <List
        sx={{
          p: 0,
          columns: { xs: 1, lg: 2, xl: maxColumns },
          columnGap: 0,
          columnFill: "balance",
          background: "#191919",
          overflow: "hidden",
          // height: { xl: "calc(100vh - 220px)" },
          boxShadow: "0 0 10px #330201",
          borderRadius: "5px",
        }}
      >
        {categories.map((category) => (
          <Fragment key={category.name}>
            <ListItem
              sx={{
                color: "white",
                textTransform: "uppercase",
                background: "#d71d0f",
                breakInside: "avoid",
                py: isSmallSize ? 1 : 2,
              }}
            >
              <Typography>{category.name}</Typography>
            </ListItem>
            {category.products.map((product) => (
              <ListItem
                key={product.name}
                sx={{
                  ...listItemsStyle,
                  color: product.inStock ? "white" : "#898989",
                  breakInside: "avoid",
                }}
              >
                <Stack direction="row" flex={1} px={2} py={isSmallSize ? 1 : 2}>
                  <Typography
                    mr="auto"
                    py={0}
                    mb={0}
                    fontSize={isSmallSize ? "1rem" : "1.3rem"}
                  >
                    {product.name}
                  </Typography>
                  {product.inStock ? (
                    <Typography
                      ml="auto"
                      py={0}
                      mb={0}
                      lineHeight="normal"
                      fontSize={isSmallSize ? "1.2rem" : "1.5rem"}
                    >
                      {product.price} kr
                    </Typography>
                  ) : (
                    <Chip
                      label="SLUT"
                      size="small"
                      sx={{ background: "#d71d0f", ml: "auto" }}
                    />
                  )}
                </Stack>
              </ListItem>
            ))}
          </Fragment>
        ))}
      </List>
    </>
  );
};

export default Kiosk;
