// src/providers/KioskProvider.tsx

import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  ReactNode,
} from "react";
import {
  Category,
  Categories,
  Products,
  Rows,
  Row,
  SwishPaymentImageUrl,
} from "../types/types";
import {
  fetchProductsSpreadsheet,
  fetchSwishPaymentImageUrl,
} from "../api/kiosk";
import { isProduction } from "../utils/is-production";
import { handleGoogleDriveImageUrl } from "../utils/handle-google-drive-image-url";

// Create the context
interface KioskContextType {
  categories: Categories;
  swishPaymentImageUrl?: SwishPaymentImageUrl;
}

const KioskContext = createContext<KioskContextType | undefined>(undefined);

// Custom hook to access the context
export const useKiosk = (): KioskContextType => {
  const context = useContext(KioskContext);
  if (context === undefined) {
    throw new Error("useKiosk must be used within a KioskProvider");
  }
  return context;
};

// Provider component
interface KioskProviderProps {
  children: ReactNode;
}

const getCategoryProductsFromRowsByIndex = (startIndex: number, rows: Rows) => {
  const products: Products = [];

  rows.map((row) => {
    const isProduct = row[startIndex];

    isProduct &&
      products.push({
        name: row[startIndex],
        inStock: row[startIndex + 1].toLowerCase() === "ja",
        category: "foo",
        price: Number(row[startIndex + 2]),
      });
  });

  return products;
};

const getAllProducts = (headerRow: Row, rows: Rows) => {
  const products: Products = [];
  const categoriesOrder = headerRow.reduce(
    (acc: string[], nextItem, currentIndex) => {
      if (currentIndex % 3 === 0) {
        acc.push(nextItem);
      }

      return acc;
    },
    []
  );

  rows.forEach((row) => {
    row.forEach((item, index) => {
      if (index % 3 === 0) {
        const isProduct = item !== "";

        if (isProduct) {
          products.push({
            name: item,
            category: headerRow[index],
            inStock: row[index + 1] === "ja",
            price: Number(row[index + 2]),
          });
        }
      }
    });
  });

  return categoriesOrder
    .map((category) => products.filter((item) => item.category === category))
    .flat();
};

export const KioskProvider: React.FC<KioskProviderProps> = ({ children }) => {
  const [categories, setCategories] = useState<Categories>([]);
  const [swishPaymentImageUrl, setSwishPaymentImageUrl] =
    useState<SwishPaymentImageUrl>();

  const updateSwishPaymentImageUrl = async () => {
    let swishPaymentImageUrl = await fetchSwishPaymentImageUrl();

    swishPaymentImageUrl = handleGoogleDriveImageUrl(
      `${swishPaymentImageUrl}`,
      600
    ) as unknown as SwishPaymentImageUrl;

    setSwishPaymentImageUrl(swishPaymentImageUrl);
  };

  const updateProducts = async () => {
    const rows = await fetchProductsSpreadsheet();

    if (rows?.length) {
      let updatedCategories: Category[] = [];
      const headerRow: string[] = rows[0];

      headerRow.map((item, index) => {
        if (index % 3 === 0) {
          updatedCategories.push({
            name: item,
            categoryIndex: index,
            products: [],
          });
        }
      });

      rows.shift();

      updatedCategories = updatedCategories.map((category) => {
        return {
          ...category,
          products: [
            ...category.products,
            ...getCategoryProductsFromRowsByIndex(category.categoryIndex, rows),
          ],
        };
      });

      setCategories(updatedCategories);
    }
  };

  useEffect(() => {
    let intervalId: number;

    updateSwishPaymentImageUrl();
    updateProducts();

    if (isProduction()) {
      intervalId = window.setInterval(() => {
        updateProducts();
      }, 1000 * 30); // Polling every 30 seconds
    }

    // Cleanup function to clear interval
    return () => {
      return window.clearInterval(intervalId);
    };
  }, []); // Only runs once on component mount

  return (
    <KioskContext.Provider value={{ categories, swishPaymentImageUrl }}>
      {children}
    </KioskContext.Provider>
  );
};
