import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  Typography,
  useTheme,
} from "@mui/material";
import { useCupboard } from "./providers/CupboardProvider";
import TeamLogo from "./TeamLogo";
import { GameStatus } from "./types/types";

const Board = () => {
  const { games } = useCupboard();
  const theme = useTheme();

  const getGameStatusColor = (status: GameStatus) => {
    const value = status.toString().toLowerCase();

    switch (value) {
      case "avgjord":
        return theme.palette.error.light;
      case "avgjord på straffar":
        return theme.palette.error.dark;
      default:
        return theme.palette.success.main;
    }
  };

  return (
    <TableContainer
      component={Paper}
      elevation={24}
      sx={{ boxShadow: "0 0 10px #330201" }}
    >
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontSize: { xs: "0.7rem" } }} align="left">
              Matchtid
            </TableCell>
            <TableCell sx={{ fontSize: { xs: "0.7rem" } }} align="left">
              Hemmalag
            </TableCell>
            <TableCell sx={{ fontSize: { xs: "0.7rem" } }} align="left">
              Bortalag
            </TableCell>
            <TableCell sx={{ fontSize: { xs: "0.7rem" } }} align="center">
              Resultat
            </TableCell>
            <TableCell sx={{ fontSize: { xs: "0.7rem" } }} align="right">
              Status
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {games.length === 0 ? (
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{ whiteSpace: "nowrap" }}
              >
                <Typography variant="caption">Hämtar spelschema ...</Typography>
              </TableCell>
            </TableRow>
          ) : (
            games.map((game, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ fontSize: { xs: "1rem" } }}
                >
                  {game.time}
                </TableCell>
                <TableCell align="left">
                  <TeamLogo name={game.homeTeam} />
                </TableCell>
                <TableCell align="left">
                  <TeamLogo name={game.awayTeam} />
                </TableCell>
                <TableCell align="center" sx={{ fontSize: { xs: "1rem" } }}>
                  {game.status.toLowerCase() === "ej påbörjad"
                    ? "-"
                    : `${game.homeGoals} - ${game.awayGoals}`}
                </TableCell>
                <TableCell align="right">
                  {game.status.toLocaleLowerCase() !== "ej påbörjad" && (
                    <Typography
                      variant="caption"
                      color="white"
                      fontSize="0.9rem"
                      px={1}
                      py={0.5}
                      fontFamily="Kimberley, sans-serif"
                      sx={{ background: getGameStatusColor(game.status) }}
                    >
                      {game.status.toUpperCase()}
                    </Typography>
                  )}
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Board;
