import { useKiosk } from "./providers/KioskProvider";

export const KioskSwishPayment = () => {
  const { swishPaymentImageUrl } = useKiosk();

  return swishPaymentImageUrl ? (
    <img
      alt="Bild för att skanna en Swish QR kod för att betala"
      src={swishPaymentImageUrl.toString()}
      style={{
        width: "100%",
        boxShadow: "0 0 10px #330201",
        borderRadius: "24px",
      }}
    ></img>
  ) : null;
};
