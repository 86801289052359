import {
  Box,
  Button,
  Card,
  Input,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { slugify } from "../utils/slugify";
import { useNavigate } from "react-router-dom";

export const MainLoggedOutLandingPage = () => {
  const navigate = useNavigate();
  const [orgName, setOrgName] = useState<string>("");
  return (
    <Stack
      height="100vh"
      justifyContent="center"
      sx={{ color: "white", textShadow: "0 0 3px black" }}
    >
      <Box m="auto" textAlign="center">
        <Stack mb={4}>
          <Typography variant="h1" fontSize="3rem" textTransform="uppercase">
            SheetViews
          </Typography>
          <Typography variant="h2" fontSize="2rem">
            Create Live Display Views From Data Sheets
          </Typography>
        </Stack>
        <Card>
          <TextField
            id="standard-name"
            fullWidth
            placeholder="Enter company or organization name"
            onChange={(element) => setOrgName(slugify(element.target.value))}
            InputProps={{
              endAdornment: (
                <Button
                  variant="contained"
                  onClick={() => navigate(orgName, { state: { hash: true } })}
                >
                  Go
                </Button>
              ),
            }}
          />
        </Card>
      </Box>
    </Stack>
  );
};
