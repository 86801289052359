import {
  createHashRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import { RootPage } from "./pages/root";
import { CupView } from "./views/cup-view";
import { MainLoggedOutLandingPage } from "./pages/main";
import { KioskView } from "./views/kiosk-view";

export const Router = createHashRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootPage />}>
      <Route path="/" element={<MainLoggedOutLandingPage />} />
      <Route path="nybro-ibk" element={<CupView />} />
      <Route path="nybro-ibk/kiosk" element={<KioskView />} />
    </Route>
  )
);
