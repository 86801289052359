export const handleGoogleDriveImageUrl = (imageUrl: string, size = 200) => {
  if (imageUrl.includes("drive.google.com/file/")) {
    return imageUrl
      .replace("drive.google.com/file", "lh3.googleusercontent.com")
      .replace("/view?usp=drive_link", `=h${size}`)
      .replace("/view?usp=sharing", `=h${size}`);
  }

  return imageUrl;
};
