import { Box } from "@mui/material";
import { useCupboard } from "./providers/CupboardProvider";

type TeamLogoProps = {
  name: string;
  height?: number;
};

const TeamLogo = (props: TeamLogoProps) => {
  const { getLogo } = useCupboard();
  const logo = getLogo(props.name);
  const height = props.height || 40;

  return logo ? (
    <Box sx={{ height }}>
      <img src={logo} alt="" height="100%" />
    </Box>
  ) : (
    <span>{props.name}</span>
  );
};

export default TeamLogo;
