import { Grid, Stack, Typography } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { CupboardProvider } from "../providers/CupboardProvider";
import theme from "../themes/theme";
import Partners from "../Partners";
import Kiosk from "../Kiosk";
import { KioskProvider } from "../providers/KioskProvider";
import kioskTheme from "../themes/kiosk-theme";
import { KioskSwishPayment } from "../KioskSwishPayment";

export const KioskView = () => {
  return (
    <ThemeProvider theme={theme}>
      <Stack flexDirection="row">
        <Stack width="100%">
          <CupboardProvider>
            <Stack
              direction={{ xs: "column", md: "row" }}
              sx={{
                p: { xs: 1, xl: 2 },
                background: "black",
                boxShadow: "0 0 10px #510602",
              }}
            >
              <Typography
                variant="h1"
                color="white"
                textAlign="center"
                sx={{ textShadow: "0 0 3px black" }}
              >
                VÄLKOMNA TILL OG-HALLEN!
              </Typography>
            </Stack>
            <Grid container spacing={2} sx={{ p: { xs: 1, xl: 2 } }}>
              <KioskProvider>
                <Grid item xs={12} xl={9} height="match-calc(100vh - 80px)">
                  <ThemeProvider theme={kioskTheme}>
                    <Kiosk size="large" maxColumns={3} />
                  </ThemeProvider>
                </Grid>

                <Grid item xs={4} lg={3}>
                  <KioskSwishPayment />
                </Grid>
              </KioskProvider>

              <Partners />
            </Grid>
          </CupboardProvider>
        </Stack>
      </Stack>
    </ThemeProvider>
  );
};
