import { Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import Board from "../Board";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { CupboardProvider } from "../providers/CupboardProvider";
import theme from "../themes/theme";
import Standings from "../Standings";
import Partners from "../Partners";
import Kiosk from "../Kiosk";
import { KioskProvider } from "../providers/KioskProvider";
import kioskTheme from "../themes/kiosk-theme";
import { KioskSwishPayment } from "../KioskSwishPayment";

export const CupView = () => {
  const { breakpoints } = useTheme();
  const isLargeScreen = useMediaQuery(breakpoints.up("xl"));

  return (
    <ThemeProvider theme={theme}>
      <Stack flexDirection="row">
        <Stack width="100%">
          <CupboardProvider>
            <Stack
              direction={{ xs: "column", md: "row" }}
              sx={{
                p: { xs: 1, xl: 2 },
                background: "black",
                boxShadow: "0 0 10px #330201",
              }}
            >
              <img
                src={`${process.env.PUBLIC_URL}/logos/nybro-ibk.png`}
                height={50}
                width={50}
                style={{ marginTop: "auto", marginBottom: "auto" }}
                alt="Nybro IBK logga"
              />

              <Typography
                variant="h1"
                color="white"
                textAlign="center"
                my="auto"
                ml={2}
                sx={{ textShadow: "0 0 3px black" }}
              >
                VÄLKOMMEN TILL OG-HALLEN!
              </Typography>

              <Typography
                variant="h2"
                color="white"
                textAlign="center"
                mr={{ xs: "auto", md: 2 }}
                ml="auto"
                my="auto"
                sx={{ textShadow: "0 0 3px black" }}
              >
                🏆 MINITURNERING 2024-04-20 🏆
              </Typography>
            </Stack>
            <Grid container spacing={2} sx={{ p: { xs: 1, xl: 2 } }}>
              <KioskProvider>
                <Grid item xs={12} xl={5}>
                  <ThemeProvider theme={kioskTheme}>
                    <Kiosk size="small" />
                  </ThemeProvider>
                </Grid>

                {isLargeScreen && (
                  <Grid item xs={4} lg={2}>
                    <KioskSwishPayment />
                  </Grid>
                )}
              </KioskProvider>
              <Grid item xs={12} xl={5}>
                <Grid container gap={2}>
                  <Grid item xs={12}>
                    <Board />
                  </Grid>
                  <Grid item xs={12}>
                    <Standings />
                  </Grid>
                </Grid>
              </Grid>

              <Partners />
            </Grid>
          </CupboardProvider>
        </Stack>
      </Stack>
    </ThemeProvider>
  );
};
