import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Typography,
  Divider,
  Stack,
} from "@mui/material";
import { useCupboard } from "./providers/CupboardProvider";
import TeamLogo from "./TeamLogo";

const Standings = () => {
  const { standings } = useCupboard();

  return (
    <>
      <TableContainer
        component={Paper}
        elevation={24}
        sx={{ boxShadow: "0 0 10px #330201" }}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontSize: { xs: "0.7rem" } }} align="left">
                RANK
              </TableCell>
              <TableCell sx={{ fontSize: { xs: "0.7rem" } }} align="left">
                LAG
              </TableCell>
              <TableCell sx={{ fontSize: { xs: "0.7rem" } }} align="left">
                SM
              </TableCell>
              <TableCell sx={{ fontSize: { xs: "0.7rem" } }} align="left">
                VM
              </TableCell>
              <TableCell sx={{ fontSize: { xs: "0.7rem" } }} align="left">
                FM
              </TableCell>
              <TableCell sx={{ fontSize: { xs: "0.7rem" } }} align="left">
                GM
              </TableCell>
              <TableCell sx={{ fontSize: { xs: "0.7rem" } }} align="left">
                IM
              </TableCell>
              <TableCell sx={{ fontSize: { xs: "0.7rem" } }} align="left">
                MS
              </TableCell>
              <TableCell sx={{ fontSize: { xs: "0.7rem" } }} align="left">
                P
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {standings.length === 0 ? (
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ whiteSpace: "nowrap" }}
                >
                  <Typography variant="caption">Hämtar tabell ...</Typography>
                </TableCell>
              </TableRow>
            ) : (
              standings.map((standing, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    "&:nth-of-type(odd)": {
                      backgroundColor: "white", // index === 0 ? "#FFD700" :

                      "td:last-child": {
                        backgroundColor: "#f5f5f5",
                      },
                    },
                    "&:nth-of-type(even)": {
                      backgroundColor: "#f7f7f7",

                      "td:last-child": {
                        backgroundColor: "#ededed",
                      },
                    },
                  }}
                >
                  <TableCell align="left" sx={{ fontSize: { xs: "1rem" } }}>
                    {`${index + 1}.`}
                  </TableCell>
                  <TableCell align="left">
                    <TeamLogo name={standing.teamName} />
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: { xs: "1rem" } }}>
                    {standing.gamesPlayed}
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: { xs: "1rem" } }}>
                    {standing.gamesWon}
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: { xs: "1rem" } }}>
                    {standing.gamesLost}
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: { xs: "1rem" } }}>
                    {standing.scoredGoals}
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: { xs: "1rem" } }}>
                    {standing.concededGoals}
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: { xs: "1rem" } }}>
                    {standing.goalDifference}
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: { xs: "1rem" } }}>
                    {standing.points}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box component={Paper} elevation={24} my={2} p={1}>
        <Stack direction="row" flexWrap="wrap">
          <Typography fontWeight={800} variant="caption" mr={1}>
            SM
          </Typography>
          <Typography variant="caption"> - Spelade matcher</Typography>
          <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
          <Typography fontWeight={800} variant="caption" mr={1}>
            VM
          </Typography>
          <Typography variant="caption"> - Vunna matcher</Typography>
          <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
          <Typography fontWeight={800} variant="caption" mr={1}>
            FM
          </Typography>
          <Typography variant="caption"> - Förlorade matcher</Typography>
          <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
          <Typography fontWeight={800} variant="caption" mr={1}>
            GM
          </Typography>
          <Typography variant="caption"> - Gjorda mål</Typography>
          <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
          <Typography fontWeight={800} variant="caption" mr={1}>
            IM
          </Typography>
          <Typography variant="caption"> - Insläppta mål</Typography>
          <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
          <Typography fontWeight={800} variant="caption" mr={1}>
            MS
          </Typography>
          <Typography variant="caption"> - Målskillnad</Typography>
          <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
          <Typography fontWeight={800} variant="caption" mr={1}>
            P
          </Typography>
          <Typography variant="caption"> - Poäng</Typography>
        </Stack>
      </Box>
    </>
  );
};

export default Standings;
