import { Rows, SwishPaymentImageUrl } from "../types/types";
import { buildApiUrl } from "../utils/build-api-url";

export const fetchProductsSpreadsheet = async () => {
  const url = buildApiUrl(
    "1txr2azNgjkqOeWr3Ap_RMoGqxrnoHINhSl1j-E-fPYg",
    "Produkter"
  );

  const response = await fetch(url);

  if (!response.ok) {
    throw new Error(
      `Failed to fetch kiosk data, status code: ${response.status}`
    );
  }

  const data = await response.json();

  return data.values as Rows;
};

export const fetchSwishPaymentImageUrl = async () => {
  const url = buildApiUrl(
    "1txr2azNgjkqOeWr3Ap_RMoGqxrnoHINhSl1j-E-fPYg",
    "Swish!A2"
  );

  const response = await fetch(url);

  if (!response.ok) {
    throw new Error(
      `Failed to fetch kiosk payments info data, status code: ${response.status}`
    );
  }

  const data = await response.json();

  return data.values[0][0] as SwishPaymentImageUrl;
};
