import { Box } from "@mui/material";
import { useCupboard } from "./providers/CupboardProvider";
import Swiper from "swiper";
import { Autoplay } from "swiper/modules";
import { SyntheticEvent, useEffect } from "react";

import "swiper/css";
import "swiper/css/autoplay";

let partnersSlider: Swiper | null = null;

const Partners = () => {
  const { partners } = useCupboard();
  useEffect(() => {
    if (partners.length > 0) {
      if (!partnersSlider) {
        partnersSlider = new Swiper(".partners", {
          // Optional parameters
          direction: "horizontal",
          loop: true,
          centeredSlides: true,
          speed: 5000,
          spaceBetween: 150,
          slidesPerView: "auto",
          allowTouchMove: false,
          observer: true,
          observeParents: true,
          autoplay: {
            delay: 0,
            disableOnInteraction: false,
          },
          modules: [Autoplay],
        });
      } else {
        console.log("update");
        partnersSlider.update();
      }
    }
  }, [partners.length]);

  const handleLoadedImage = (ev: SyntheticEvent<HTMLImageElement>) => {
    const img = ev.target as HTMLImageElement;

    const isPortrait = img.naturalHeight >= img.naturalWidth;

    if (isPortrait) {
      img.style.width = "auto";
    } else {
      const newHeight = (img.naturalHeight / img.naturalWidth) * 200;
      if (newHeight > 60) {
        const newWidth = (img.naturalWidth / img.naturalHeight) * 60;
        img.style.width = `${newWidth}px`;
      } else {
        img.style.height = `${newHeight}px`;
      }
    }
  };

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        right: 0,
        left: 0,
        display: "flex",
        boxShadow: "0 0 25px #330201",
      }}
    >
      <Box
        height="60px"
        width="100%"
        py={2}
        sx={{
          background: "#0d0d0d",
          borderTop: "8px solid #620702",
          outline: "2px solid #940e07",
        }}
      >
        <div className="swiper partners">
          <div
            className="swiper-wrapper"
            style={{ transitionTimingFunction: "linear" }}
          >
            {partners.map((partner) => (
              <div
                key={partner.name}
                className="swiper-slide"
                style={{
                  height: 60,
                  width: 200,
                  display: "flex",
                }}
              >
                <img
                  src={partner.imageUrl}
                  alt={partner.name}
                  loading="lazy"
                  onLoad={handleLoadedImage}
                  style={{
                    height: "60px",
                    width: "200px",
                    margin: "auto",
                  }}
                />
                <div className="swiper-lazy-preloader"></div>
              </div>
            ))}
          </div>
        </div>
      </Box>
    </Box>
  );
};

export default Partners;
