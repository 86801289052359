import { Game, GameStatus, Games, Standing } from "../types/types";

const initialTeamValues = {
  gamesPlayed: 0,
  gamesWon: 0,
  gamesLost: 0,
  points: 0,
  goalDifference: 0,
  scoredGoals: 0,
  concededGoals: 0,
};

type StandingsAccumulator = {
  [key: string]: Standing;
};

export const calculateStandings = (games: Games) => {
  const reducer = (accumulator: StandingsAccumulator, currentValue: Game) => {
    const status = currentValue.status.toLowerCase() as GameStatus;

    // Helper function to update team statistics
    const updateTeamStats = (
      team: string,
      goalsFor: number,
      goalsConceded: number,
      won: boolean,
      penaltyLost: boolean,
      penaltyWon: boolean
    ) => {
      accumulator[team].gamesPlayed++;
      accumulator[team].scoredGoals += goalsFor;
      accumulator[team].concededGoals += goalsConceded;

      if (won) {
        accumulator[team].gamesWon++;
        if (penaltyWon) {
          accumulator[team].points += 2;
        } else {
          accumulator[team].points += 3;
        }
      } else {
        accumulator[team].gamesLost++;
        if (penaltyLost) {
          accumulator[team].points += 1;
        }
      }
    };

    // Update statistics for home team
    if (!accumulator[currentValue.homeTeam]) {
      accumulator[currentValue.homeTeam] = {
        teamName: currentValue.homeTeam,
        ...initialTeamValues,
      };
    }

    // Update statistics for away team
    if (!accumulator[currentValue.awayTeam]) {
      accumulator[currentValue.awayTeam] = {
        teamName: currentValue.awayTeam,
        ...initialTeamValues,
      };
    }

    if (status === "avgjord" || status === "avgjord på straffar") {
      // Determine the winner of the game based on penalty shootout
      const homeTeamWins = currentValue.homeGoals > currentValue.awayGoals;
      const awayTeamWins = currentValue.awayGoals > currentValue.homeGoals;

      // Update home team statistics
      updateTeamStats(
        currentValue.homeTeam,
        currentValue.homeGoals,
        currentValue.awayGoals,
        homeTeamWins,
        awayTeamWins && status === "avgjord på straffar",
        homeTeamWins && status === "avgjord på straffar"
      );

      // Update away team statistics
      updateTeamStats(
        currentValue.awayTeam,
        currentValue.awayGoals,
        currentValue.homeGoals,
        awayTeamWins,
        homeTeamWins && status === "avgjord på straffar",
        awayTeamWins && status === "avgjord på straffar"
      );
    }

    return accumulator;
  };

  const result = games.reduce(reducer, {});

  // Calculate goals in head-to-head matches for each team pair
  function mostGoalsInHeadToHead(team1: string, team2: string) {
    let goalsTeam1 = 0;
    let goalsTeam2 = 0;

    // Iterate through the games to find matches between the two teams
    games.forEach((game) => {
      if (
        (((game.homeTeam === team1 && game.awayTeam === team2) ||
          (game.homeTeam === team2 && game.awayTeam === team1)) &&
          game.status === "avgjord") ||
        game.status === "avgjord på straffar"
      ) {
        goalsTeam1 += game.homeTeam === team1 ? game.homeGoals : game.awayGoals;
        goalsTeam2 += game.homeTeam === team2 ? game.homeGoals : game.awayGoals;
      }
    });

    return {
      [team1]: goalsTeam1,
      [team2]: goalsTeam2,
    };
  }

  // Calculate goal difference
  for (const team in result) {
    result[team].goalDifference =
      result[team].scoredGoals - result[team].concededGoals;
  }

  // Sort the results considering points, goal difference and goal difference in head-to-head matches
  const sortedResult = Object.values(result).sort((a, b) => {
    if (a.points !== b.points) {
      return b.points - a.points; // Sort by points
    } else if (b.goalDifference !== a.goalDifference) {
      return b.goalDifference - a.goalDifference; // If points are equal, sort by goal difference
    } else {
      // If points are equal, compare the goal difference in head-to-head matches
      const team1 = a.teamName;
      const team2 = b.teamName;
      const goalsInHeadToHead = mostGoalsInHeadToHead(team1, team2);
      const goalDifferenceInHeadToHead =
        goalsInHeadToHead[team1] - goalsInHeadToHead[team2];
      return goalDifferenceInHeadToHead;
    }
  });

  return sortedResult;
};
