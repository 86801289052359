import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: "Kimberley, sans-serif",
  },
});

theme.typography.body1 = {
  ...theme.typography.body1,
  fontFamily: "Helvetica, sans-serif",
};

theme.typography.caption = {
  ...theme.typography.caption,
  fontFamily: "Helvetica, sans-serif",
};

theme.typography.h2 = {
  ...theme.typography.h2,
  fontSize: "1rem",
  [theme.breakpoints.up("sm")]: {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "2rem",
  },
};

theme.typography.h3 = {
  ...theme.typography.h3,
  fontSize: "1rem",
  [theme.breakpoints.up("md")]: {
    fontSize: "1.5rem",
  },
};

theme.typography.h1 = {
  ...theme.typography.h1,
  fontSize: "2rem",
  [theme.breakpoints.up("md")]: {
    fontSize: "2rem",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "2rem",
  },
};

export default theme;
