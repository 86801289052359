// src/providers/CupboardProvider.tsx

import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  ReactNode,
} from "react";
import {
  Game,
  GameStatus,
  Games,
  Partners,
  Standings,
  Teams,
} from "../types/types";
import { calculateStandings } from "../utils/calculate-standings";
import { fetchPartnersSpreadsheet } from "../api/partners";
import { fetchGamesSpreadsheet } from "../api/games";
import { fetchTeamsSpreadsheet } from "../api/teams";
import { isProduction } from "../utils/is-production";
import { handleGoogleDriveImageUrl } from "../utils/handle-google-drive-image-url";

// Create the context
interface CupboardContextType {
  games: Games;
  teams: Teams;
  partners: Partners;
  standings: Standings;
  getLogo: (teamName: string) => string | null | undefined;
}

const CupboardContext = createContext<CupboardContextType | undefined>(
  undefined
);

// Custom hook to access the context
export const useCupboard = (): CupboardContextType => {
  const context = useContext(CupboardContext);
  if (context === undefined) {
    throw new Error("useCupboard must be used within a CupboardProvider");
  }
  return context;
};

// Provider component
interface CupboardProviderProps {
  children: ReactNode;
}

export const CupboardProvider: React.FC<CupboardProviderProps> = ({
  children,
}) => {
  const [games, setGames] = useState<Games>([]);
  const [teams, setTeams] = useState<Teams>([]);
  const [partners, setPartners] = useState<Partners>([]);
  const [standings, setStandings] = useState<Standings>([]);

  const updateParters = async () => {
    const rows = await fetchPartnersSpreadsheet();

    if (rows?.length) {
      rows.shift();

      const updatedPartners: Partners = rows.map((row) => {
        const partner = {
          name: String(row[0]),
          imageUrl: String(row[1]),
        };

        partner.imageUrl = handleGoogleDriveImageUrl(partner.imageUrl);

        // https://drive.google.com/file/d/1ZgNZB0HBbRCPMQI2cRupwHj8lpnKltiv/view?usp=drive_link
        // https://drive.google.com/thumbnail?id=1ZgNZB0HBbRCPMQI2cRupwHj8lpnKltiv&sz=w200
        // https://lh3.googleusercontent.com/d/1ZgNZB0HBbRCPMQI2cRupwHj8lpnKltiv=h200?authuser=0

        return partner;
      });

      setPartners(updatedPartners);
    }

    return teams;
  };

  const updateTeams = async () => {
    const rows = await fetchTeamsSpreadsheet();

    if (rows?.length) {
      rows.shift();

      const updatedTeams: Teams = rows.map((row) => ({
        name: String(row[0]),
        logo: row[1],
      }));

      setTeams(updatedTeams);

      return updatedTeams;
    }

    return [];
  };

  const updateGames = async () => {
    const rows = await fetchGamesSpreadsheet();

    if (rows?.length) {
      rows.shift();

      const updatedGames: Game[] = rows.map((row) => ({
        time: String(row[0]),
        homeTeam: String(row[1]),
        awayTeam: String(row[2]),
        homeGoals: Number(row[3]),
        awayGoals: Number(row[4]),
        status: row[5] as GameStatus,
      }));

      setStandings(calculateStandings(updatedGames));

      setGames(updatedGames);

      return updatedGames;
    } else {
      // res.status(404).json({ message: 'No data found in the Google Sheet.' });
      return null;
    }
  };

  useEffect(() => {
    const makeInitialTeamRequest = async () => {
      await updateTeams();
      updateGames();
    };

    updateParters();

    makeInitialTeamRequest();
  }, []); // Only runs once on component mount

  // Polling effect
  useEffect(() => {
    let intervalId: number;

    if (isProduction() && teams.length > 0) {
      intervalId = window.setInterval(() => {
        updateGames();
      }, 1000 * 5); // Polling every 5 seconds
    }

    // Cleanup function to clear interval
    return () => {
      return window.clearInterval(intervalId);
    };
  }, [teams]);

  const getLogo = (teamName: string) => {
    if (teams) {
      const team = teams.find(
        (prospect) => prospect.name.toLowerCase() === teamName.toLowerCase()
      );

      return team ? `${process.env.PUBLIC_URL}/logos/${team.logo}` : null;
    }
  };

  return (
    <CupboardContext.Provider
      value={{ games, teams, standings, partners, getLogo }}
    >
      {children}
    </CupboardContext.Provider>
  );
};
